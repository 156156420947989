<template>
  <div class="all-building-characteristic">
    <AdminTable title="All Building Characteristics" :list="buildingCharacteristicList" :fields="fields" :loading="loading"
                @update-list="update" @edit="editCharacteristic" @delete="deleteCharacteristic"/>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'

import { buildingCharacteristicService } from '@/services/admin'
import { mapState } from 'vuex'

export default {
  name: 'AdminAllBuildingCharacteristic',
  components: {
    AdminTable,
  },
  data() {
    return {
      buildingCharacteristicList: [],
      loading: false,
    }
  },
  computed: {
    ...mapState({
      characteristics: state => state.adminCharacteristicStore.characteristics(),
    }),
    fields() {
      return [
        { key: 'id', label: 'ID'},
        { key: 'name', label: 'Name'},
        { key: 'defaultValue', label: 'Default Value' },
        { key: 'translations.en', label: 'English', flag: 'us' },
        { key: 'translations.it', label: 'Italiano', flag: 'it' },
        { key: 'translations.es', label: 'Español', flag: 'es' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit', 'delete'] },
      ]
    }
  },
  beforeMount() {
    this.buildingCharacteristicList = this.getCharacteristicList()
  },
  methods: {
    async getCharacteristicList() {
      this.loading = true
      this.buildingCharacteristicList = await this.$store.dispatch('adminCharacteristicStore/getCharacteristics')
      this.$store.commit('adminCharacteristicStore/setCharacteristics', this.buildingCharacteristicList)
      this.loading = false
    },
    editCharacteristic(characteristic) {
      this.$store.commit('adminCharacteristicStore/setCharacteristic', characteristic)
      this.$router.push({ name: 'building-characteristic-edit', query: { id: characteristic.id } })
    },
    async deleteCharacteristic(characteristic) {
      await buildingCharacteristicService.deleteCharacteristic(characteristic.id)
      await this.getCharacteristicList()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
